import request from '@/libs/request'

// 第一块屏幕按钮
export function  getIndex(params) {
    return request({
        url: '/class_menu',
        method: 'get',
        params: params
    })
}

// 代表联络站 / 基层立法点菜单 /
export function  allmenu(params) {
    return request({
        url: '/two_menu',
        method: 'get',
        params: params
    })
}

// 主要工作菜单
export function  activityList(params) {
    return request({
        url: '/activityList',
        method: 'get',
        params: params
    })
}

export function  activityScreenList(params) {
    return request({
        url: '/activityScreenList',
        method: 'get',
        params: params
    })
}

// 文章详情
export function  articledetails(params) {
    return request({
        url: '/activityDetails',
        method: 'get',
        params: params
    })
}

// 文章详情
export function  activityDetailsTwo(params) {
    return request({
        url: '/activityDetailsTwo',
        method: 'get',
        params: params
    })
}

// 建议列表
export function  messageList(params) {
    return request({
        url: '/messageList',
        method: 'get',
        params: params
    })
}

// 提交建议
export function messageAdd(params) {
    return request({
        url: '/messageAdd',
        method: 'post',
        params: params
    })
}

// 上传录音
export function uploadaudio (params) {
    return request({
        url: '/upload/image',
        method: 'post',
        params: params
    })
}

// 一级图文列表
export function imageTxtData (params) {
    return request({
        url: '/imageTxtData',
        method: 'get',
        params: params
    })
}

// 图文年份列表
export function categoryImgList (params) {
    return request({
        url: 'free/categoryImgList',
        method: 'get',
        params: params
    })
}

// 一级分类名+文章列表
export function categoryImgListOne (params) {
    return request({
        url: 'categoryImgListOne',
        method: 'get',
        params: params
    })
}

//一级分类名+文章列表 (多图)
export function categoryImgListTwo (params) {
    return request({
        url: 'categoryImgListTwo',
        method: 'get',
        params: params
    })
}

//一级分类名+文章列表 (多图)
export function categoryImgScreenListTwo (params) {
    return request({
        url: 'categoryImgScreenListTwo',
        method: 'get',
        params: params
    })
}

//一级分类+二级分类
export function newcategory (params) {
    return request({
        url: 'category1',
        method: 'get',
        params: params
    })
}

//十个一轮播详情
export function newxiangqing (params) {
    return request({
        url: 'xiangqing1',
        method: 'get',
        params: params
    })
}
// 第一块屏幕按钮
export function  getScreen(params) {
    return request({
        url: '/screen_home',
        method: 'get',
        params: params
    })
}
// 第一块屏幕按钮
export function  getScreenMenu(params) {
    return request({
        url: '/screen_menu',
        method: 'get',
        params: params
    })
}