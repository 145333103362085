<template>
  <div class="onebg acea-row row-middle row-center">
    <div class="nav acea-row row-middle row-around">
      <div class="" @click="click(item)"  :key="index" v-for="(item,index) in icon_list" >
        <img class="active" :src="item.image" alt="" >
      </div>

    </div>
  </div>
</template>
<script>
import { getScreen } from '@/api/api'
import Cookies from "js-cookie";
export default {
  name:"newIndex",
  data(){
    return{
      tabindex: 0,
      list:[
      ],
      icon_list:[],
      listid:[],


    }
  },
  mounted() {
    if(this.$route.query.tk){
      Cookies.set('tk',this.$route.query.tk);
    }
    if(this.$route.query.screen_id){
      this.screen_id=this.$route.query.screen_id;
      Cookies.set('screen_id',this.$route.query.screen_id);
    }
    this.getIndex();
  },
  methods:{
    getIndex(){
      let that = this;
      getScreen({'screen_id':this.screen_id}).then(res=>{
        that.icon_list=res.data.data;
        that.listid = res.data.data;
      })
    },
    click(val){
      let that=this;
      this.tabindex = val;
      that.$goto(val);
    },
  }
}
</script>
<style lang="scss" scoped>
.nav{
  width: 90%;
  margin-top: 100px;

  .index_icon{
    width: 500px;
    height: 500px;
  }

  .hover{
    width: 570px;
    height: 510px;
  }

  .active{
    width: 460px;
    height: 430px;
  }


}


</style>
