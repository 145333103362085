<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import ajax from '@/libs/ajax'
import Cookies from 'js-cookie'
export default {
  data(){
    return{
      tk:'',
    }
  },
  mounted() {
    if(this.$route.query.tk){
      this.tk=this.$route.query.tk
    }else{
      this.tk=Cookies.get('tk');
    }
    let that=this;
    that.$nextTick(function(){

      // eslint-disable-next-line no-prototype-builtins
      if(that.$link.hasOwnProperty('pc_onebg')){
        setTimeout(function (){
          that.setViewback(that.$link)
        },100)
      }else{
        that.getConfig();
      }
    })

  },
  watch:{
    $route(to, from){
      let that=this;
      /*
      // eslint-disable-next-line no-prototype-builtins
      if(this.$link.hasOwnProperty('pc_onebg')){
        setTimeout(function (){
          that.setViewback(that.$link)
        },100)
      }else{
        this.getConfig();
      }
      */
      // eslint-disable-next-line no-prototype-builtins
      if(this.$MerInfo.hasOwnProperty('title')){
        document.title=that.$MerInfo.title;
      }else{
        this.getConfig();
      }

    }
  },
  methods:{
    getConfig(){
      ajax({
        url: '/getScreenConfig?tk='+this.tk,
        method: 'get',
      }).then(res=>{
        this.$link=res.data.data;
        this.$MerInfo=res.data.merInfo;
        Cookies.set('Merinfo_id',res.data.merInfo.id);
        this.setViewback(res.data.data)
        Cookies.set('qrcode_url',res.data.merInfo.qrcode_url);
        document.title=res.data.merInfo.title;

      })
    },
    setViewback(data){
    }
  }
}
</script>
<style lang="scss">
*{
  padding: 0;
  margin: 0;
}
//flex布局
.acea-row{display:flex;flex-wrap:wrap;}
.acea-row.row-top{align-items:flex-start;}
.acea-row.row-middle{align-items:center;}
.acea-row.row-bottom{align-items:flex-end;}
.acea-row.row-left{justify-content:flex-start;}
.acea-row.row-center{justify-content:center;}
.acea-row.row-right{justify-content:flex-end;}
.acea-row.row-between{justify-content:space-between;}
.acea-row.row-around{justify-content:space-around;}
.acea-row.row-column{flex-direction:column;}
.acea-row.row-column-between{flex-direction:column;justify-content:space-between;}
.acea-row.row-column-around{flex-direction:column;justify-content:space-around;}
.acea-row.row-center-wrapper{align-items:center;justify-content:center;}
.acea-row.row-between-wrapper{align-items:center;justify-content:space-between;}

//背景
.onebg{
  width: 100vw;
  height: 100vh;
  background-image: url("https://nanrongcun.oss-cn-hangzhou.aliyuncs.com/pic/indexbg.png");
  background-size: 100% 100%;
}

.threebg{
  width: 100vw;
  height: 100vh;
  background-image: url("https://nanrongcun.oss-cn-hangzhou.aliyuncs.com/pic/towbg.png");
  background-size: 100% 100%;
}

.allbg{
  width: 100vw;
  height: 100vh;
  background-image: url("https://nanrongcun.oss-cn-hangzhou.aliyuncs.com/pic/allbg.png");
  background-size: 100% 100%;
}

.new-back-btn{
  width: 295px;
  height: 120px;
  position: absolute;
  bottom: 10px;
  right: 80px;
  background-size: 100% 100%;
  background-image: url("https://nanrongcun.oss-cn-hangzhou.aliyuncs.com/pic/backbtn.png");
  z-index: 999;
}

.new-btn{
  width: 712px;
  height: 260px;
  background-size: 100% 100%;
  color: #fff;
  font-size: 44px;
  text-transform:uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  //text-shadow:2px 3px 1px #247D14;
}

.imgwh{
  width: 100%;
  height: 100%;
}

.bold{
  font-weight: bold;
}

.text-center{
  text-align: center;
}

</style>
