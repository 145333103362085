import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/newIndex.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Index
    },
    {
        path: '/ContactPoints',
        name: 'ContactPoints',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ContactPoints.vue')
    },
    {
        path: '/WuDagoneng',
        name: 'WuDagoneng',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/WuDagoneng.vue')
    },
    {
        path: '/GongNengdetails',
        name: 'GongNengdetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/GongNengdetails.vue')
    },
    {
        path: '/BtnList',
        name: 'BtnList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/BtnList.vue')
    },
    {
        path: '/ThroughTrain',
        name: 'ThroughTrain',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ThroughTrain.vue')
    },
    {
        path: '/PastDates',
        name: 'PastDates',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PastDates.vue')
    },
    {
        path: '/TrainSuc',
        name: 'TrainSuc',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/TrainSuc.vue')
    },
    {
        path: '/TextContent',
        name: 'TextContent',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/TextContent.vue')
    },
    {
        path: '/SuBtn',
        name: 'SuBtn',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/SuBtn.vue')
    },
    {
        path: '/PublicNotice',
        name: 'PublicNotice',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PublicNotice.vue')
    },
    {
        path: '/PublicNoticedetails',
        name: 'PublicNoticedetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PublicNoticedetails.vue')
    },
    {
        path: '/YearContent',
        name: 'YearContent',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/YearContent.vue')
    },
    {
        path: '/PalyVideo',
        name: 'PalyVideo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PalyVideo.vue')
    },
    {
        path: '/SonBtn',
        name: 'SonBtn',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/SonBtn.vue')
    },
    {
        path: '/SecondaryBtn',
        name: 'SecondaryBtn',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/SecondaryBtn.vue')
    },
    {
        path: '/ActiveDetails',
        name: 'ActiveDetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ActiveDetails.vue')
    },
    {
        path: '/Active',
        name: 'Active',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Active.vue')
    },
    {
        path: '/LvzhiStar',
        name: 'LvzhiStar',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/LvzhiStar.vue')
    },
    {
        path: '/ThroughH5',
        name: 'ThroughH5',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ThroughH5.vue')
    },
    {
        path: '/ThrougHsuc',
        name: 'ThrougHsuc',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ThrougHsuc.vue')
    },
    {
        path: '/AudioH5',
        name: 'AudioH5',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AudioH5.vue')
    },
    {
        path: '/ThrougH5submit',
        name: 'ThrougH5submit',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ThrougH5submit.vue')
    },
    {
        path: '/ThroughDetail',
        name: 'ThroughDetail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ThroughDetail.vue')
    },
    {
        path: '/PictrueAll',
        name: 'PictrueAll',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PictrueAll.vue')
    },
    {
        path: '/SwiperBox',
        name: 'SwiperBox',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/SwiperBox.vue')
    },
    {
        path: '/LiuChengtu',
        name: 'LiuChengtu',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/LiuChengtu.vue')
    },
    {
        path: '/FileBtn',
        name: 'FileBtn',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/FileBtn.vue')
    },
    {
        path: '/FileDetails',
        name: 'FileDetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/FileDetails.vue')
    },
    {
        path: '/FormPic',
        name: 'FormPic',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/FormPic.vue')
    },
    {
        path: '/LawBtn',
        name: 'LawBtn',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/LawBtn.vue')
    },
    {
        path: '/LawBtnTwo',
        name: 'LawBtnTwo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/LawBtnTwo.vue')
    },
    {
        path: '/JianyiBanli',
        name: 'JianyiBanli',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/JianyiBanli.vue')
    },
    {
        path: '/ImagesLIst',
        name: 'ImagesLIst',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ImagesLIst.vue')
    },
    {
        path: '/PicrtrueDetail',
        name: 'PicrtrueDetail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PicrtrueDetail.vue')
    },
    {
        path: '/TextList',
        name: 'TextList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/TextList.vue')
    },
    {
        path: '/PastdatesDetails',
        name: 'PastdatesDetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PastdatesDetails.vue')
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

