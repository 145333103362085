import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '../src/assets/css/font.css'
import  'amfe-flexible';

import offall from "@/components/Offall.vue";

import axios from 'axios';


Vue.prototype.$axios = axios;

Vue.use(ElementUI)

Vue.component('offall',offall)

Vue.config.productionTip = false

Vue.prototype.$link=[];
Vue.prototype.$MerInfo=[];
Vue.MerId='';

Vue.prototype.$goto=function (data){
  // eslint-disable-next-line no-prototype-builtins
  if(data.hasOwnProperty('viewInfo')){
    if(data.viewInfo.action.length){
      this.$router.push('/'+data.viewInfo.link+'?'+data.viewInfo.action+'&id='+data.id)
    }else{
      this.$router.push('/'+data.viewInfo.link+'?id='+data.id)
    }
  }
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

